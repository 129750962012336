<template>
  <div>
    <b-card title="日志记录">
      <div class="custom-search">
        <!-- advance search input -->
        <b-row class="mb-2">
          <b-col md="3">
            <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="操作账号" label-for="input-lg">
              <b-form-input id="input-lg" v-model="searchAccountName" placeholder="请输入操作账号" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="姓名" label-for="input-lg">
              <b-form-input id="input-lg" v-model="searchUserName" placeholder="请输入姓名" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="日志类型" label-for="input-lg">
              <v-select label="description" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="logTypeOptions" class="w-100" :reduce="val => val.value" v-model="enumValue"
                placeholder="请选择日志类型" />
            </b-form-group>
          </b-col>
          <b-col md="3" class="tRight">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="pageLog(true)">
              搜索
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div>
        <vue-good-table ref="ruleTable" :columns="columns" :rows="rows" :rtl="direction"
          :sort-options="{enabled: false,}" :search-options="{enabled: true,externalQuery: searchTerm }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" :pagination-options="{
            enabled: true,
            perPage:pageSize
          }" theme="my-theme">
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <b-dropdown variant="link" no-caret>
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                </template>
                <!-- 查看 -->
                <b-dropdown-item @click.stop="getLogDetail(props.row.id)">
                  <span>
                    <feather-icon icon="VoicemailIcon" />
                    <span class="align-middle ml-50">查看</span>
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="tCenter">
              <b-pagination v-model="currentPage" :total-rows="total" :per-page="pageSize" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @change="currentPageHandleChange">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </template>
        </vue-good-table>
      </div>
      <el-dialog title="详情" :visible.sync="logDetailDialogVisible" width="40%" center>
        <el-form>
          <el-form-item label="路径" label-width="120px">
            <el-input v-model="logDetail.controllerName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="名称" label-width="120px">
            <el-input v-model="logDetail.methodName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="活动区域" label-width="120px">
            <el-input type="textarea" :rows="10" placeholder="请求详情" v-model="logDetail.detail">
            </el-input>
          </el-form-item>
        </el-form>
      </el-dialog>
    </b-card>
  </div>

</template>

<script>
import {
  BAvatar,BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BButton,BDropdown,BDropdownItem,BPagination
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import request from '@/api/request'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BAvatar,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BForm,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      logDetail:{},
      searchTerm:'',
      dir: false,
      searchAccountName:'',
      searchUserName:'',
      enumValue:'',
      logTypeOptions: [
        {
          value: 0,
          description: ''
        }
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      rows:[],
      columns: [
        {
          label: '编号',
          field: 'id',
        },
        {
          label: '操作账号',
          field: 'accountName',
        },
        {
          label: '账号姓名',
          field: 'userName',
        },
        {
          label: '事件类型',
          field: 'logDescription',
        },
        {
          label: '账号人机构',
          field: 'tenantName',
        },
        {
          label: '操作时间',
          field: 'operationTime',
        },
        {
          label: '操作',
          field: 'action',
        },
      ],
      logDetailDialogVisible: false
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created(){
    this.getLogTypeList()
    this.pageLog(true)
  },
  methods: {
    getLogTypeList(){
      request.get('tob/log/bLog/enums').then(res => {
        console.log('tob/log/bLog/enums',res)
        if (res.data.success) {
          this.logTypeOptions = res.data.data
          console.log('total',this.total)
        }
      })
    },
    pageLog(resetPage){
      if(resetPage)
        this.currentPage = 1
      request.get('tob/log/bLog/page', {
        enumValue: this.enumValue,
        accountName: this.searchAccountName,
        userName: this.searchUserName,
        pageSize: this.pageSize,
        currentPage: this.currentPage
      }).then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.rows = res.data.data.data
          this.total = res.data.data.count
          console.log('total',this.total)
        }
      })
    },
    getLogDetail(id){
      request.get('tob/log/bLog/detail', {id}).then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.logDetail = res.data.data
          this.logDetailDialogVisible = !this.logDetailDialogVisible
        }
      })
    },
    currentPageHandleChange(currentPage){
      this.currentPage = currentPage
      this.pageLog()
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
